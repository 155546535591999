.categories-section {
  background-color: #FE7474;
}

.list-style{
  color: #FFD2D2;
  text-align: left;
  width: 90%;
  margin: 15px auto;
  font-size: 16px;
  line-height: 30px;
  font-weight: bold;
}

.list-item {
  img {
    height: 20px;
    width: 20px;
  }
  span {
    margin-left: 10px;
  }
}