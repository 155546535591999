.coral-red {
  color: #ff4343;
}

.bg-coral-red {
  background-color: #ff4343;
}

.gray29 {
  color: #4a4a4a;
}

.white {
  color: #ffffff;
}

.bg-salmon {
  background-color: #ff7171;
}

.dark-grey {
  color: #c4c4c4;
}

.light-grey {
  color: #a9a9a9;
}
