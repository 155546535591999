.community-card {
  width: 90%;
  margin: auto;
}

.card-top-section {
  .col-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.card-metadata {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.card-image {
  background: #c4c4c4;
  width: 70px;
  height: 70px;
  border-radius: 100px;
  margin-left: 10px;
}

.card-info-section {
  padding: 0 10px 10px 10px;
}
