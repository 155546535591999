#rcorners2 {
  border-radius: 10px;
}

.mb-10em {
  margin-bottom: 10em;
}

.slide {
  background: none !important;

  .card {
    border: none;
    background: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
}