/* The Modal (background) */
@import "~bootstrap/scss/bootstrap";
.modal {
    /*display: none; /* Hidden by default */
     position: fixed; /* Stay in place */
     z-index: 1; /* Sit on top */
     padding-top: 100px; /* Location of the box */
     left: 0;
     top: 0;
     width: 100%; /* Full width */
     height: 100%; /* Full height */
     overflow: auto; /* Enable scroll if needed */
     background-color: rgb(0,0,0); /* Fallback color */
     background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
   }
   
   /* Modal Content */
   .modal-content {
     position: relative;
     background-color: #fefefe;
     margin: auto;
     padding: 0;
     border: 1px solid #888;
     width: 80%;
     box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
     -webkit-animation-name: animatetop;
     -webkit-animation-duration: 0.4s;
     animation-name: animatetop;
     animation-duration: 0.4s
   }
   
   /* Add Animation */
   @-webkit-keyframes animatetop {
     from {top:-300px; opacity:0} 
     to {top:0; opacity:1}
   }
   
   @keyframes animatetop {
     from {top:-300px; opacity:0}
     to {top:0; opacity:1}
   }
   
   /* The Close Button */
   .close {
     color: white;
     float: right;
     font-size: 24px;
     font-weight: bold;
   }
   
   .close:hover,
   .close:focus {
     color: #000;
     text-decoration: none;
     cursor: pointer;
   }
   
   .modal-header {
     padding: 2px 16px;
     background-color: #5cb85c;
     color: white;
   }
   
   .modal-body {padding: 2px 16px;}
   
   .modal-footer {
     padding: 2px 16px;
     background-color: #5cb85c;
     color: white;
   }


  

.logo-section-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 50%;
  padding: 0;

  img {
    width: 50%;
  }

  .logo-img {
    width: 50%;
  }

  .logo-img-small {
    width: 50%;
  }
}
.navbar {
  margin-bottom: 50px !important;
}

.header-img {
  width: 100%;
  height: auto;
}

.search-input {
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border: none;
  height: 40px;
  width: 100%;
  padding: 0 15px;
  outline: none;

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a9a9a9;
  }
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #a9a9a9;
  }
  
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #a9a9a9;
  }
}

.close-icon {
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
  background-color: #FF4343;
  border-radius: 50%;
  padding: 5px;
  height: 40px !important;
  width: 40px !important;
  color: #FFFFFF;
}