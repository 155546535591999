@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "./colors.scss";

// .App {
//   text-align: center;
// }

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Custom styles

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pl10 {
  padding-left: 10px;
}

.pr10 {
  padding-right: 10px;
}

.p5 {
  padding: 5px;
}

.pt25 {
  padding-top: 25px;
}

.pb25 {
  padding-bottom: 25px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb30 {
  padding-bottom: 30px;
}

.p30 {
  padding: 30px;
}

.ml0 {
  margin-left: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.m10 {
  margin: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.mt140 {
  margin-top: 140px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.section-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: 700;
}

.white {
  color: #ffffff;
}

.bg-white {
  background: #ffffff;
}

.bg-grey {
  background: #cbcbcb !important;
}

// modal classes
.custom-modal-container {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
}

.overlay {
  position: fixed;
  z-index: 1050 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.6);
}

.custom-modal-dialog {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  z-index: 9999 !important;
}

.video-box {
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background-color: #ffffff;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.infinite-scroll-component {
  width: 100%;
}
