.loading-background {
  width: 100%;
  background: linear-gradient(270deg, #ffffff, #ececec, #dfdfdf, #ffffff);
  background-size: 800% 800%;

  -webkit-animation: loadingBackgroundShimmer 3s ease infinite;
  -moz-animation: loadingBackgroundShimmer 3s ease infinite;
  animation: loadingBackgroundShimmer 3s ease infinite;
}

@-webkit-keyframes loadingBackgroundShimmer {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes loadingBackgroundShimmer {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes loadingBackgroundShimmer {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}