.section-title-container {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  text-align: left;
}

.categories-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.category-box {
  display: flex;
  justify-content: center;
  min-height: 120px;
  background: #ffffff;
  box-shadow: 2px 3px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  flex-direction: column;
}
  img,
  .loading-background {
    object-fit: contain;
    height: 75%;
    width: 100%;
    margin: 0 auto;
  }
  .product-logo {
    max-width:100%;
    height:75%;
    margin: 14px auto 0px auto;
    border-radius:20px; 
  }

  .category-text {
    font-size: 14px;
  }

  p {
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #696969;
    margin: auto;
  }