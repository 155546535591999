.footer-section {
  background-image: url("../../assets/images/BG.jpg");
  background-repeat: no-repeat;
}

.footer-bottom-card-section {
  background: linear-gradient(180deg, #ff4343 0%, #ff3a5e 100%), #ff4343;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  box-shadow: 0 0.5rem 1.5rem 0em rgba(0, 0, 0, 1) !important;
  height: 13em;

  p {
    text-align: center;
    margin-bottom: 0.5em;
  }
}

.footer-bottom-card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: auto;
}

.footer-top-curved {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.cta-row {
  width: 70%;
}

.footer-cta {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  text-align: center;
  width: 90%;
  margin: auto;
  font-family: sans-serif;
}
.hr-line {
  border: 2px solid #fff;
}
.footerLink {
  font-size: 12px;
  margin-top: 24px;
  padding: 0px 9px 5px 13px;
  color: #fff;
}
.footerLink a {
  color: #fff;
}
