.cart-container {
  margin-top: 80px;
  padding-top: 15px;
  text-align: center;

  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
.cart-style {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  color: #4a4a4a;
}
.cart-container1 {
  position: relative;
  height: 260px;
  left: 0px;
  margin-top: 20px;

  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.cart-img {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 32px;
  top: 30px;

  background: #eff3fe;
  border-radius: 5px;
}
.cart-img-text {
  position: absolute;
  width: 200px;
  height: 21px;
  left: 142px;
  top: 30px;
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #373737;
  padding-right: 42px;
}
.cart-img-text1 {
  position: absolute;
  width: 200px;
  height: 19px;
  left: 142px;
  top: 50px;

  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #696969;
}
.cart-amount {
  position: absolute;
  width: 157px;
  height: 29px;
  left: 32px;
  top: 150px;

  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 29px;
  color: #ff6969;
}
.cart-amount-right {
  position: absolute;
  height: 29px;
  right: 50px;
  top: 150px;

  font-family: SF Pro Text;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */
  padding-right: 6%;
  text-align: right;

  color: #ff6969;
}
.cart-bottom-card {
  height: 8em;
  &__info {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
  }

  .footer-cta {
    width: 40%;
    margin: unset;
  }
}

.cart-item {
  background: #ffffff;
  border-radius: 15px;
}

.cart-thumbnail {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.cart-delete-icon {
  position: absolute;
  top: 30px;
  right: 2px;
  color: #ffffff;
  background-color: #ff6969;
  border-radius: 15px;
  padding: 7px;
  height: 30px !important;
  width: 30px !important;
}
