.similarProduct {
  overflow: auto;
  white-space: nowrap;
}
div.similarProduct .similarProductListItem {
  width: 35vw;
  height: 14vh;
  background: #fff;
  border-radius: 6px;
  display: inline-block;
  color: white;
  text-decoration: none;
  margin-right: 12px;
  text-align: center;
}

.similarProductListItem img {
  height: 100%;
  width: auto;
}

.post {
  position: relative;
  overflow: hidden;
}
span .more-link {
  font-weight: bold;
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  padding: 90px 0 20px 0;
  background-image: linear-gradient(to bottom, transparent, white);
}
