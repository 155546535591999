.down-arrow-icon {
  height: 1.2em;
  width: 1.2em;
  position: absolute;
  right: 5px;
}

.quantitySpanLeft {
  width: 20%;
  background-color: #9e9e9e;
  border-radius: 4px 1px 1px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.quantitySpanMiddle {
  width: 30%;
  background-color: #9e9e9e70;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quantitySpanRight {
  width: 20%;
  background-color: #f44336;
  border-radius: 1px 4px 4px 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
