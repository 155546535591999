.top-navbar {
  background: #ffffff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  max-height: 70px;
  position: fixed;
  top: 70px;
  right: 0;
  left: 0;
  z-index: 1000;
  padding: 15px 10px;

  .nav-link-bar {
    border: solid 1px #cbcbcb;
    width: 90%;
    margin: auto;
    border-radius: 25px;
  }
}

.nav-link {
  color: #a9a9a9;
  text-align: center;

  a,
  a:hover {
    text-decoration: none;
    color: #a9a9a9;
  }
}

.navlink-border-right {
  border-right: solid 1px #cbcbcb;
}

.active-link {
  background: linear-gradient(180deg, #ff4343 0%, #ff3a5e 100%), #ffffff;
  color: #fff !important;
}

.left-col {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.right-col {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
