@import "~bootstrap/scss/bootstrap";

.logo-section-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 50%;
  padding: 0;

  img {
    width: 50%;
  }

  .logo-img {
    width: 50%;
  }

  .logo-img-small {
    width: 50%;
  }
}
.navbar {
  margin-bottom: 50px !important;
}

.header-img {
  width: 100%;
  height: auto;
}

.header-link.header-active-link {
   img {
    z-index: 1;
    filter: brightness(0.5);
  }

  .header-active-link-bg{
    position: absolute;
    background: white;
    top: -9px;
    left: 8px;
    height: 45px;
    width: 45px;
    border-radius: 25px;
    z-index: -1;
  }
}
