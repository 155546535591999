@import "~bootstrap/scss/bootstrap";
.logo-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 50%;
}
.section-container {
  background-color: #F7F7F7;
}
.text-styling {
  font-weight: 700;
  font-size: 30px;
  color: red;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logged-in-home-section {
  margin-top: 150px;
  padding: 20px;
  font-size: 20px;
  line-height: 25px;

  p {
    margin-bottom: 0;
  }
}

.bg-image {
  background-image: url("../../assets/images/BG.jpg");
  background-repeat: repeat;
  background-size: cover;
}

.user-credit {
  color: #FF4343;
  font-weight: bold;
  text-transform: uppercase;
}
